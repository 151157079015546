import { get as _get } from 'lodash-es'
import footerNavigation from 'components/Footer/footerNavigation.json'
import { Footer } from '@achieve/cx-universal-footer'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import { Typography, TypographyLongForm } from 'components/Contentful'
import { MediaImageStandard } from 'components/Contentful'
import { AscendThemeProvider } from '@achieve/ascend'
import { GlobalContext } from 'providers/GlobalProvider'
import { useContext } from 'react'

export default function FooterContainer({
  disabledRoutes,
  content,
  secondaryDisclosures,
  showNav = true,
  hiddenNavSection = [],
  showFooterSocialIcons,
}) {
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()

  const { pageDisclosure } = useContext(GlobalContext)
  let footerMenu = footerNavigation.footerMenus
  const brandCopy = _get(content, 'logoHelpText')
  const menuContent = _get(content, 'footerItems')
  const jsonConfig = _get(content, 'config')
  const markersResources = _get(content, 'markers') ?? footerNavigation.markers
  const copyrightMessage = _get(
    content,
    'copyrightMessage.content[0].content[0].value',
    'Achieve.com. All rights reserved.'
  )

  const footerSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    applicationCategory: jsonConfig?.schema?.applicationCategory,
    applicationSubCategory: jsonConfig?.schema?.applicationSubCategory,
    name: jsonConfig?.schema?.nameOrganization,
    aggregateRating: {
      reviewCount: [Number(jsonConfig?.schema?.reviewCount?.replace(/,/g, ''))],
      ratingValue: [Number(jsonConfig?.schema?.ratingValue?.replace(/,/g, ''))],
      bestRating: 5,
    },
    offers: {
      '@type': jsonConfig?.schema?.offersType,
      price: jsonConfig?.schema?.offetPrice,
      priceCurrency: jsonConfig?.schema?.offetPriceCurrency,
    },
    operatingSystem: 'All',
    copyrightYear: new Date().getFullYear(),
    description: brandCopy,
  }

  const footerOrganizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: jsonConfig?.schema?.nameOrganization,
    alternateName: jsonConfig?.schema?.webpageAlternativeOrganization,
    url: jsonConfig?.schema?.webpageOrganization,
    logo: { '@type': 'ImageObject', url: jsonConfig?.schema?.oganizationLogo },
    image: jsonConfig?.schema?.oganizationImage,
    description: brandCopy,
    sameAs: jsonConfig?.schema?.oganizationSocialMediaLinks,
    address: {
      '@type': 'PostalAddress',
      streetAddress: jsonConfig?.schema?.streetAddress,
      addressLocality: jsonConfig?.schema?.addressLocality,
      addressRegion: jsonConfig?.schema?.addressRegion,
      postalCode: jsonConfig?.schema?.postalCode,
      addressCountry: { '@type': 'Country', name: 'US' },
    },
    telephone: jsonConfig?.schema?.telephone,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        type: 'ContactPoint',
        telephone: jsonConfig?.schema?.postalCode,
        contactType: 'customer service',
        areaServed: ['US'],
        availableLanguage: jsonConfig?.schema?.availableLanguage,
      },
    ],
  }

  if (menuContent) {
    footerMenu = []
    for (let i = 0; i < menuContent.length; i++) {
      const element = menuContent[i]
      const subMneu = []
      for (let j = 0; j < element?.fields?.gridItems.length; j++) {
        const subElement = element?.fields?.gridItems[j]
        const label = _get(subElement, 'fields.linkText.content[0].content[0].value')
        const url = _get(subElement, 'fields.linkHref')
        if (!disabledRoutes.includes(url) && url.length && label.length) {
          subMneu.push({
            label,
            url,
          })
        }
      }
      if (subMneu.length) {
        const menuLabel = _get(element, 'fields.name')
        if (!hiddenNavSection.includes(menuLabel))
          footerMenu.push({
            menuLabel: menuLabel,
            menuItems: subMneu,
          })
      }
    }
  }

  return (
    <AscendThemeProvider>
      <Footer
        footerNavigation={footerMenu}
        pageDisclosure={[pageDisclosure, ...secondaryDisclosures]?.map((dataDisclosure, index) => (
          <div key={index}>
            <TypographyLongForm content={dataDisclosure} variant="bodyXs" fontWeight="regular" />
          </div>
        ))}
        markersSection={markersResources?.map((marker, index) => {
          return (
            <div key={`marker-img-${index}`}>
              <MediaImageStandard
                alt={marker.fields.name}
                content={marker.fields.image}
                width={90}
                height={60}
                objectFit="contain"
              />
              {marker.fields.subtitle !== '.' && (
                <Typography
                  content={marker.fields.subtitle}
                  variant="bodyXs"
                  fontWeight="regular"
                  style={{ textAlign: 'center', marginTop: -2 }}
                  fontSize="8px"
                />
              )}
            </div>
          )
        })}
        logoImage="/2022_Achieve_Logo_RGB.svg"
        brandCopy={brandCopy}
        trackingCB={analyticsTrackEvent}
        copyrightMessage={copyrightMessage}
        showNav={showNav}
        showFooterSocialIcons={showFooterSocialIcons}
      />
      <script
        id="review"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(footerSchema) }}
      />
      <script
        id="organization"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(footerOrganizationSchema) }}
      />
    </AscendThemeProvider>
  )
}
